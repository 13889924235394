<template>
  <vbt-content-box
    :loading-error="loadingError"
    :no-content="noContent"
    :loading="loading"
    title="Information"
  >
    <template #toolbar>
      <v-layout align-center>
        <v-spacer />

        <v-text-field
          v-model.trim="machineCategoryName"
          :error="isNotUniqueMachineCategoryName"
          :label="`Machine category name ${isNotUniqueMachineCategoryName ? '(not unique)' : ''}`"
          class="vbt-hide-form-control-details"
          @keydown.enter="createMachineCategory"
        >
          <template #append>
            <v-btn
              small
              :loading="loading"
              :disabled="isInvalidMachineCategoryNameForm"
              class="create-machine-category-activator"
              color="success"
              @click="createMachineCategory"
            >
              Create
            </v-btn>
          </template>
        </v-text-field>
      </v-layout>
    </template>

    <v-layout
      class="mt-2"
      column
    >
      <template v-for="(category, i) in machineCategories">
        <v-layout :key="`layout-${category.id}`">
          <v-flex sm3>
            <strong class="mr-1">
              Name:
            </strong>

            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  :to="{
                    name: 'machineCategoryHistory',
                    params: { id: category.id },
                    query: { suffix },
                  }"
                  icon
                  x-small
                  color="info"
                  target="_blank"
                  v-on="on"
                >
                  <v-icon>
                    history
                  </v-icon>
                </v-btn>
              </template>

              History
            </v-tooltip>

            {{ category.name }}
          </v-flex>

          <v-flex :class="visibleCategoryMachinesEditorId === category.id ? 'sm3' : 'sm1'">
            <v-text-field
              v-if="visibleCategoryMachinesEditorId === category.id"
              v-model.trim="machineName"
              :error="isNotUniqueMachineName"
              :label="`Machine name ${isNotUniqueMachineName ? '(not unique)' : ''}`"
              class="vbt-hide-form-control-details"
              style="width: 300px;"
              autofocus
              @keydown.enter="addMachineToCategory"
              @keydown.esc="toggleCategoryMachinesEditor"
            >
              <template #append>
                <v-layout>
                  <v-btn
                    :disabled="isInvalidMachineNameForm"
                    icon
                    x-small
                    color="success"
                    @click="addMachineToCategory"
                  >
                    <v-icon>
                      check_circle
                    </v-icon>
                  </v-btn>

                  <v-btn
                    icon
                    x-small
                    color="error"
                    @click="toggleCategoryMachinesEditor"
                  >
                    <v-icon>
                      cancel
                    </v-icon>
                  </v-btn>
                </v-layout>
              </template>
            </v-text-field>

            <strong
              v-else
              class="mr-3"
            >
              Machines

              <v-btn
                icon
                x-small
                color="success"
                @click="toggleCategoryMachinesEditor(category.id)"
              >
                <v-icon>
                  add_circle
                </v-icon>
              </v-btn>
            </strong>
          </v-flex>

          <v-flex :class="visibleCategoryMachinesEditorId === category.id ? 'sm6' : 'sm8'">
            <v-chip
              v-for="(machine, i) in category.machines || []"
              :key="`machine-${category.id}-${i}`"
              class="mr-2 mb-2"
              small
            >
              {{ machine }}

              <v-btn
                icon
                x-small
                color="error"
                class="ml-1 mr-n2"
                @click="removeMachineFromMachineCategory(category.id, machine)"
              >
                <v-icon>
                  delete
                </v-icon>
              </v-btn>
            </v-chip>
          </v-flex>
        </v-layout>

        <v-divider
          v-if="i < machineCategories.length - 1"
          :key="`divider-${category.id}`"
          :class="(category.machines || []).length ? 'mt-1' : 'mt-3'"
          class="mb-3"
        />
      </template>
    </v-layout>
  </vbt-content-box>
</template>

<script>
import { mapState } from 'vuex';

import { useDataLoading } from '@mixins/factories';

import { wrapToLoadingFn } from '@helpers';

import { AppLocationsNamespace } from '@store/types';

import { MachineCategoriesApiService } from '../_services/machineCategories.api.service';

const dataLoadingOptions = {
  getterName: 'getMachineCategories',
  contentName: 'machineCategories',
  searchable: false,
  paged: false,
};

export default {
  name: 'DsbMachineCategories',

  mixins: [
    useDataLoading(dataLoadingOptions),
  ],

  data() {
    return {
      machineCategories: [],

      machineCategoryName: '',
      machineName: '',

      visibleCategoryMachinesEditorId: '',
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),

    isInvalidMachineCategoryNameForm() {
      return !this.machineCategoryName || this.isNotUniqueMachineCategoryName;
    },

    isNotUniqueMachineCategoryName() {
      return this.machineCategories.some(({ name }) => name === this.machineCategoryName);
    },

    isInvalidMachineNameForm() {
      return !this.machineName || this.isNotUniqueMachineName;
    },

    isNotUniqueMachineName() {
      const { machines } = this.machineCategories
        .find(({ id }) => id === this.visibleCategoryMachinesEditorId) || {};

      return (machines || []).some((machineName) => machineName === this.machineName);
    },
  },

  methods: {
    getMachineCategories(params) {
      return this.wrapToLoadingFn({
        req: MachineCategoriesApiService.getMachineCategories.bind({}, params),
        onSuccess: (data) => {
          this.$set(this, 'machineCategories', data);
        },
      });
    },

    createMachineCategory() {
      if (this.isInvalidMachineCategoryNameForm) {
        return;
      }

      this.$VBlackerTheme.alert.warning({
        text: 'Create machine category?',
      }, () => this.wrapToLoadingFn({
        req: MachineCategoriesApiService.createMachineCategory.bind({}, {
          name: this.machineCategoryName,
          suffix: this.suffix,
        }),
        fullscreenLoadingSpinner: true,
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Successfully created');
          this.machineCategoryName = '';
          this.$_fetchData();
        },
      }));
    },

    toggleCategoryMachinesEditor(categoryId = '') {
      if (this.visibleCategoryMachinesEditorId) {
        this.visibleCategoryMachinesEditorId = '';
        this.machineName = '';
      } else {
        this.visibleCategoryMachinesEditorId = categoryId;
      }
    },

    addMachineToCategory() {
      if (this.isInvalidMachineNameForm) {
        return;
      }

      this.wrapToLoadingFn({
        req: MachineCategoriesApiService.addMachineToMachineCategory.bind({}, {
          categoryId: this.visibleCategoryMachinesEditorId,
          machineName: this.machineName,
          suffix: this.suffix,
        }),
        fullscreenLoadingSpinner: true,
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Successfully created');
          this.toggleCategoryMachinesEditor();
          this.$_fetchData();
        },
      });
    },

    removeMachineFromMachineCategory(categoryId, machineName) {
      this.$VBlackerTheme.alert.warning({
        text: `Remove ${machineName}?`,
      }, () => this.wrapToLoadingFn({
        req: MachineCategoriesApiService.removeMachineFromMachineCategory.bind({}, {
          suffix: this.suffix,
          machineName,
          categoryId,
        }),
        fullscreenLoadingSpinner: true,
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Successfully removed');
          this.$_fetchData();

          if (this.visibleCategoryMachinesEditorId) {
            this.toggleCategoryMachinesEditor();
          }
        },
      }));
    },

    wrapToLoadingFn,
  },
};
</script>

<style lang="css" scoped>
.create-machine-category-activator {
  border-radius: 0 5px 0 0;
  margin-right: -1px;
}
</style>
